/*
  component: mat-radio-group

  `<mat-radio-group class=".nv-mat-radio-group"></mat-radio-group>`
*/
.nv-mat-radio-group {
  mat-radio-button {
    // --mat-mdc-radio-checked-ripple-color and --mat-mdc-radio-ripple-color can be used to disabled undesirable ripple
    --mat-mdc-radio-checked-ripple-color: unset !important;
    --mat-mdc-radio-ripple-color: unset !important;
    &.mat-mdc-radio-button {
      .mat-radio-ripple {
        .mat-ripple-element {
          background-color: unset;
        }
      }
    }
    .mdc-form-field {
      label {
        padding-left: 0;
        cursor: pointer;
      }
    }
  }
}
