@import '../styles/nvTypography';
@import '../styles/nvColors';
@import '../styles/mdConstants';

//
// Dense tables
//
$dense-table-row-height: $baseline-grid * 4;
$extra-dense-table-row-height: $baseline-grid * 3.5;

// `nv-table` and `nv-table-dense` are for plain-old tables. Don't use it on md-data-tables!
// `nv-data-table` and `nv-data-table-dense` are for md-data-tables
// To make an .nv-table responsive, wrap it with .nv-table-container

//nv-table-dense-container
div.nv-table-container {
  overflow: auto;
}

table.nv-table {
  width: 100%;
  border-spacing: 0;
  overflow: hidden;

  tr {
    height: 48px;
    transition: background-color 0s;
    background-color: inherit;
  }

  td {
    color: $nv-primary-text-color;
    font-size: $font-size-medium;
    border-top: 1px solid $nv-rule-color;
  }

  th {
    color: $nv-secondary-text-color;
    font-size: $font-size-small;
    font-weight: bold;
    padding-bottom: 5px;
    text-align: left;
    &.col-header-button {
      // For sort
      cursor: pointer;
    }
    &:focus {
      outline: none !important;
    }
    span {
      &:focus {
        outline: none !important;
      }
      &.sort-direction {
        color: $nv-primary-text-color;
        font-size: 75%;
      }
      &.sort-direction:after {
        content: '\25b2'; // BLACK UP-POINTING TRIANGLE
      }
      &.sort-direction.reverse-sort:after {
        content: '\25bc'; // BLACK DOWN-POINTING TRIANGLE
      }
      &.active-sort {
        color: $nv-primary-text-color;
      }
    }
  }

  th,
  td {
    padding-bottom: 0;
    padding-top: 0;
    padding-left: $baseline-grid;
    padding-right: $baseline-grid;
    vertical-align: middle;
    &.left-align {
      text-align: left !important;
    }
    &.right-align {
      text-align: right !important;
    }
    &.center-align {
      text-align: center !important;
    }
    &.number-col {
      text-align: center !important;
    }
    &.score-col {
      font-size: $font-size-medium;
      font-weight: $font-weight-medium;
    }
  }

  tbody {
    tr {
      &:not([disabled]):hover {
        background: $nv-row-hover !important;
      }
    }
  }
}

table.nv-table-dense {
  tr {
    height: $dense-table-row-height;
  }
}

table.nv-table-extra-dense {
  tr {
    height: $extra-dense-table-row-height;
  }
}

// For md-data-table tables. Don't use on plain tables!
table.nv-data-table {
  td {
    color: $nv-primary-text-color;
    font-size: $font-size-medium;
  }

  th {
    color: $nv-secondary-text-color;
    font-size: $font-size-small;
    font-weight: $font-weight-medium;
    padding-bottom: 5px;
    &.col-header-button {
      // For sort
      cursor: pointer;
    }
    span {
      &:focus {
        outline: none !important;
      }
      &.sort-direction {
        color: $nv-primary-text-color;
        font-size: 75%;
      }
      &.sort-direction:after {
        content: '\25b2'; // BLACK UP-POINTING TRIANGLE
      }
      &.sort-direction.reverse-sort:after {
        content: '\25bc'; // BLACK DOWN-POINTING TRIANGLE
      }
      &.active-sort {
        color: $nv-primary-text-color;
      }
    }

    &.double-header {
      // Double header row on school credit metrics page
      color: $nv-primary-text-color;
    }
  }

  th,
  td {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    padding-left: $baseline-grid !important;
    padding-right: $baseline-grid !important;
    vertical-align: middle;
    &.left-align {
      text-align: left !important;
    }
    &.right-align {
      text-align: right !important;
    }
    &.center-align {
      text-align: center !important;
    }
    &.number-col {
      text-align: center !important;
    }
    &.score-col {
      font-size: $font-size-medium;
      font-weight: $font-weight-medium;
    }
  }

  td.md-cell.md-numeric,
  th.md-column.md-numeric {
    text-align: center;
  }

  tbody {
    tr {
      &:not([disabled]):hover {
        background-color: $nv-row-hover !important;
      }
    }
  }
}

// For md-data-table tables. Don't use on plain tables!
table.nv-data-table-dense {
  tr {
    height: $dense-table-row-height !important;
  }
}

table.nv-data-table-extra-dense {
  tr {
    height: $extra-dense-table-row-height !important;
  }
}
