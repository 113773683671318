/*
  component: mat-autocomplete

  `<div class="nv-mat-autocomplete">
    <form>
      <mat-form-field>
        <input>
        <mat-autocomplete >
          <mat-option>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>
  </div>`
*/
// Used in nv-textbox
.mat-mdc-autocomplete-panel-above .nv-mat-autocomplete {
  &.mdc-menu-surface--open {
    margin-bottom: var(--space-s);
  }
}

.nv-mat-autocomplete {
  &.mat-mdc-autocomplete-panel.mdc-menu-surface--open {
  padding: 0;
  max-height: 256px;
  margin-top: var(--space-s);
  overflow-x: hidden;
  // transition: all 0.5s ease-in-out;
  }

  .mat-mdc-option.nv-mat-option {
    padding: 0 var(--space-m);
    cursor: pointer;
    line-height: 1em;

    &:hover {
      background: var(--color-blue-10);
    }

    &.mat-active {
      background: var(--color-blue-05);
    }

    // Material is very specific...
    &.mat-mdc-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
      color: var(--color-blue);
    }

    .mdc-list-item__primary-text,
    &.mat-active .mdc-list-item__primary-text {
      width: 100%;
      height: 100%;
      display: grid;
      grid-auto-flow: row;
      grid-row-gap: var(--space-xxs);
      cursor: pointer;
      align-items: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      .nv-mat-option-label {
        font-size: var(--font-xs);
        font-weight: 500;
        color: var(--color-blue);

        & em {
          background-color: var(--color-yellow-50);
          color: var(--color-navy);
          font-style: normal;
        }
      }
      .nv-mat-option-tags {
        font-size: var(--font-xxs);
        opacity: 0.5;
        text-transform: uppercase;
        font-weight: bold;
        text-overflow: ellipsis;
        overflow-x: hidden;
        color: var(--color-blue);

        & em {
          background-color: var(--color-yellow-50);
          color: var(--color-navy);
          font-style: normal;
        }
      }
    }

    &.nv-mat-option-empty_state {
      font-style: italic;
      opacity: 0.5;
      cursor: default;
      pointer-events: none;

      &:hover,
      & *:hover {
        background: transparent;
        cursor: default;
      }
    }

    &.nv-mat-option-empty_state_action {
      // background: purple;
      .mdc-list-item__primary-text {
        // background: green;
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: var(--space-xl);
        align-items: center;

        span {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    .mat-mdc-option-ripple.mat-ripple * {
      // display: none;
      opacity: 0.1;
      background: var(--color-navy);
    }
  }

  mat-form-field {
    @extend %nv-mat-form-field;
    .mdc-text-field--filled {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          padding-bottom: var(--space-s);
        }
      }
      padding: 0;
    }

    .mat-mdc-form-field-subscript-wrapper {
      height: 0;
    }

    .mat-form-field-infix {
      border: 0;
    }

    &.mat-form-field-invalid,
    &.mat-focused {
      .mat-mdc-input-element {
        caret-color: black;
      }

      .mat-form-field-label {
        color: var(--color-blue-50);
      }
    }
  }
}

/**
  * Global styles for autocomplete panel
  * These styles reset padding of the autocomplete panel to what the default was prior to angular material v15
*/
.mat-mdc-autocomplete-panel.mdc-menu-surface {
  &.mdc-menu-surface--open {
    padding: 0;
  }
}
