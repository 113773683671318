@import './variables.scss';
@import './components.scss';
@import './custom-material.scss';
@import './custom-theme.scss';
@import './../../../../src/app/core/styles/nvMaterial.scss';

html {
  color: var(--color-blue);
  // box-sizing: border-box;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--font-family-normal);
}

* code {
  font-family: var(--font-family-mono);
}
