// Define the base color map
$BaseColors: (
  'white': #ffffff,
  'black': #000000,
  'red': rgba(194, 0, 24, 1),
  'pink': rgba(255, 92, 143, 1),
  'salmon': rgba(255, 137, 137, 1),
  'yellow': rgba(255, 196, 0, 1),
  'green': rgba(0, 155, 72, 1),
  'teal': rgba(10, 196, 172, 1),
  'blue': rgba(10, 74, 206, 1),
  'navy': rgba(0, 1, 101, 1),
  'purple': rgba(102, 45, 145, 1),
);

:root {
  // create css color variables for each item in base color map
  @each $name, $color in $BaseColors {
    --color-#{$name}: #{$color};
    --color-#{$name}-90: #{mix($color, white, 90%)};
    --color-#{$name}-80: #{mix($color, white, 80%)};
    --color-#{$name}-70: #{mix($color, white, 70%)};
    --color-#{$name}-60: #{mix($color, white, 60%)};
    --color-#{$name}-50: #{mix($color, white, 50%)};
    --color-#{$name}-40: #{mix($color, white, 40%)};
    --color-#{$name}-30: #{mix($color, white, 30%)};
    --color-#{$name}-20: #{mix($color, white, 20%)};
    --color-#{$name}-10: #{mix($color, white, 10%)};
    --color-#{$name}-5: #{mix($color, white, 5%)};
    --color-#{$name}-3: #{mix($color, white, 3%)};
    --color-#{$name}-1: #{mix($color, white, 1%)};
    --color-#{$name}-dark: #{mix($color, black, 90%)};
    --color-#{$name}-dark-80: #{mix($color, black, 80%)};
    --color-#{$name}-dark-70: #{mix($color, black, 70%)};
    --color-#{$name}-dark-60: #{mix($color, black, 60%)};
    --color-#{$name}-dark-50: #{mix($color, black, 50%)};
  }
  // Legacy variables
  --color-blue-05: var(--color-blue-5);
  --color-blue-03: var(--color-blue-3);
  --color-blue-01: var(--color-blue-1);
  --color-red-pill: --var(--color-pink);
}
