// @import url('https://fonts.googleapis.com/css?family=Roboto+Mono');
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:100,100i,300,300i,400,400i,500,500i,700,700i|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap&subset=latin-ext');

:root {
  --font-xxxs: 10px;
  --font-xxs: 12px;
  --font-xs: 14px;
  --font-s: 16px;
  --font-m: 18px;
  --font-l: 20px;
  --font-xl: 22px;
  --font-xxl: 24px;
  --font-xxxl: 32px;

  --font-family-normal: 'Roboto', --apple-system, sans-serif;
  --font-family-mono: 'Roboto Mono', Courrier, monospace;
}
