// As much as possible use angular material fonts
// .md-display-4 Light 112px
// .md-display-3 Regular 56px
// .md-display-2 Regular 45px
// .md-display-1 Regular 34px
// .md-headline Regular 24px
// .md-title Medium 20px
// .md-subhead Regular 16px
// .md-body-1 Regular 14px
// .md-body-2 Medium 14px
// .md-button MEDIUM 14PX
// .md-caption Regular 12px

//TYPOGRAPHY
$font-family: Roboto, 'Helvetica Neue', sans-serif !important;
$font-weight-medium: 500;
$font-weight-light: 400;
$font-size-huge: 24px;
$font-size-extra-large: 16px;
$font-size-extra-extra-large: 18px;
$font-size-large: 14px;
$font-size-medium: 13px;
$font-size-small: 12px;
$font-size-extra-small: 10px;
$sidenav-links-font-size: 14px;
$sidenav-links-font-weight: $font-weight-medium;
$sidenav-custom-icons-height: 24px;

//https://material.google.com/style/color.html#color-text-background-colors
$nv-primary-text-color: rgba(0, 0, 0, 0.87);
$nv-secondary-text-color: rgba(0, 0, 0, 0.54);
$nv-disabled-text-color: rgba(0, 0, 0, 0.38);
$nv-caption-text-color: $nv-disabled-text-color;
