@import './../ng2/school/lists/list-display/list-display.scss';
@import './../app/core/styles/nvTables.scss';
@import './../ng2/shared/modals/modals.component.scss';
@import '../nvps-libraries/design/styles/nv-global.scss';

.unformatted-link {
  color: inherit; 
  text-decoration: inherit;
  height: 100%;
  width: 100%;
}