/**
  Assuming you have use the modals.service (ng2/shared/modals/modals.service.ts)
  to define an initial modal config object, all modals will be wrapped in `nv-mat-dialog`.

  Styles for `nv-mat-dialog` are defined in custom-material.scss (ng2/styles/custom-material.scss).
  Going forward if you need to override any angular material elements,
  like `mat-form-field` or other, try to do so in that file, NOT in this file.

  This file should be reserved for defining rulesets for dialogs/modals
  when just overriding angular material elements is not sufficient.

  CAVEAT: If UI design calls for modal specific tweaks to an angular material element
  we can do that here. Meaning, if the student report modal needs to have a
  slightly different implementation of `mat-form-field` than what is defined for all of the other
  modals, it is OK to define that here.

  - JYR

  At the end of this file, styling for .nv-dialog is placed there TEMPORALLY.
  It is  been used by add-college, add-career, alert and list-summary modal.
  TODO: we need to dry them up further and refactor their templates/components structure to inherit from Jamie's base modal architecture

  - JChu
*/

@import '../../styles/variable-components.scss';

// TODO-jchu: TEMPORARILY rename nv-modal with flag-modal, needs to restructure with base-modal architecture
.flag-modal {
  color: var(--color-blue);

  .solution {
    display: grid;
    grid-template-areas:
      'headline'
      'solutions'
      'textarea'
      'actions';
    grid-template-columns: auto;
    grid-template-rows: var(--space-xxxl) auto 120px auto;
    grid-auto-columns: unset;
    grid-row-gap: var(--space-xl);

    h2 {
      grid-area: 'headline';
      font-weight: normal;
      padding: 0px;
      margin: 0px;
    }

    .solutions {
      grid-area: 'solutions';
      align-self: center;

      .choices {
        display: grid;
        grid-auto-rows: auto;
        grid-auto-columns: auto;
        align-items: flex-end;

        .choice {
          cursor: pointer;
          display: grid;
          grid-template-columns: 1fr;
          border-bottom: var(--space-xxxs) solid var(--color-blue-20);
          line-height: var(--space-xxxl);

          label {
            height: 100%;
          }
        }
      }

      .choices:last-child {
        grid-row: 'intrinsic';
      }
    }

    textarea {
      grid-area: 'textarea';
      width: 100%;
      border: var(--space-xxxs) solid var(--color-blue-10);
      background: var(--color-blue-05);
      outline: 0px none transparent;
      padding: var(--space-s);
      font-size: var(--space-m);
      display: block;
      box-sizing: border-box;
      color: var(--color-blue);
    }
  }

  .actions {
    align-self: flex-end;
    text-align: right;
    grid-area: 'actions';

    button {
      letter-spacing: var(--space-xxxs);
      font-size: var(--space-m);
      font-weight: 500;
      height: var(--space-xxl);

      &.resolve {
        color: white;
        background-color: var(--color-blue);
      }
    }
  }
}

// TODO: move to base-modal.scss when able
.base-modal-shell {
  box-sizing: border-box;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'actions';
  grid-template-rows: 1fr max-content minmax(1fr max-content);
  grid-auto-flow: row;
  grid-row-gap: var(--space-m);
  color: var(--color-blue);
  background: var(--color-white);
  border-radius: var(--space-xxs);

  // common to all dialog-content containers
  p {
    margin: 0;
    color: var(--color-blue);
  }

  // TODO - remove this when all modals use nv-form-field
  label {
    color: var(--color-blue);
    font-size: var(--font-xxs);
    margin-bottom: var(--space-s);
    line-height: 1.33;
    display: inline-block;
    letter-spacing: normal;
    font-weight: bold;
    width: 100%;
    opacity: 0.5;
  }
  // Undo the styling done above
  nv-form-field label {
    display: unset;
    width: unset;
    margin-bottom: unset;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    letter-spacing: inherit;
    opacity: unset;
  }
  
  // dialog-content area specific
  .dialog-header {
    display: grid;
    grid-area: header;
    grid-template-rows: 1fr min-content;
    grid-template-columns: 32px 1fr;
    grid-column-gap: 4px;
    align-items: center;
  
    .dialog-title {
      font-size: 24px;
      color: var(--color-blue);
    }
    .dialog-subtitle {
      grid-column: 2/3;
      font-size: 14px;
      font-weight: 500;
      color: var(--color-blue-60);
    }
  }
  
  // TODO: this is parent to ng-content. Add child class for each modal variant as variants
  .dialog-body {
    //   mat-label.placeholder {
    //     color: var(--color-blue-50);
    //     font-size: var(--font-s);
    //     line-height: 44px;
    //     display: block;
    //     width: 100%;
    //     padding-left: var(--space-xxl);
    //     font-weight: normal;
  
    //     &.disabled {
    //       display: none;
    //     }
    //   }
    //   grid-area: body;
    //   grid-auto-flow: row;
    //   align-content: center;
    //   // grid-row-gap: var(--space-m);
    //   align-content: center;
    //   // when there is an input that changes dialog-body content based on field selected
  
    //   mat-form-field {
    //     max-width: 100%;
    //     overflow-x: hidden;
    //     input {
    //       text-overflow: ellipsis;
    //       white-space: nowrap;
    //       overflow: hidden;
    //     }
    //   }
  
    //   //cdk-overlay
    //   mat-autocomplete {
    //     display: block;
    //     mat-option.mat-option {
    //       line-height: var(--space-l);
    //       font-size: var(--font-xs);
    //       font-weight: 500;
    //       letter-spacing: var(--space-xxxs);
    //       padding: var(--space-s);
    //       cursor: pointer;
    //       background-color: var(--color-white);
    //       text-align: left;
  
    //       &:hover {
    //         background-color: var(--color-blue-05);
    //       }
  
    //       span.mat-option-text {
    //         font-weight: 500;
    //         color: var(--color-blue);
    //       }
    //     }
    //   }
  
    //   .spinner {
    //     text-align: center;
    //     mat-progress-spinner {
    //       margin: 0 auto;
    //     }
    //   }
  
    //   p {
    //     opacity: 1;
    //     font-size: var(--font-s);
    //   }
  
    //   .field-selected {
    //     // TODO-jchu: test the following code is redundant
    //     color: var(--color-blue);
    //     border-radius: 5px;
    //     font-weight: 500;
    //     display: block;
    //     font-size: var(--font-xs);
    //     font-weight: 500;
    //     letter-spacing: var(--space-xxxs);
  
    //     .mat-form-field-infix {
    //       mat-icon {
    //         // grid-area: icon;
    //         position: absolute;
    //         right: var(--space-s);
    //         top: 50%;
    //         transform: translate(0, -50%);
    //       }
    //     }
    //   }
  }
  
  // TODO in some modal variants, the markup for this might live inside dialog-body.
  .dialog-actions {
    grid-area: actions;
    grid-auto-flow: column;
    align-self: flex-end;
    text-align: right;
    justify-content: flex-end;
    grid-column-gap: var(--space-s);
    padding: 0;
    margin-bottom: -24px; // Default margin prior to material v15
  }

  .mat-mdc-dialog-content {
    padding: 0;
  }
}


/*
  component: mat-dialog

  `
    this.dialog.open(CustomModalComponent, {
      panelClass: 'nv-mat-dialog'
    });
  `
*/
.nv-mat-dialog {
  mat-dialog-container {
    .mdc-dialog__surface {
      padding: var(--space-xl);
      width: 500px;
    }
  }
}

.nv-mat-dialog-sm {
  mat-dialog-container {
    .mdc-dialog__surface {
      padding: var(--space-xl);
      width: 250px;
    }
  }
}

.nv-mat-dialog-med {
  mat-dialog-container {
    .mdc-dialog__surface {
      padding: var(--space-xl);
      width: 600px;
    }
  }
}

.nv-mat-dialog-med-no-padding {
  mat-dialog-container {
    .mdc-dialog__surface {
      padding: 0;
      width: 600px;
    }
  }
}

.nv-mat-dialog-no-padding-no-overflow-wide {
  mat-dialog-container {
    .mdc-dialog__surface {
      padding: 0;
      width: 800px;
      max-height: 90vh;
      min-width: 700px;
      min-height: 90vh;
      overflow: hidden;
    }
  }
}

.nv-mat-dialog-wide {
  mat-dialog-container {
    .mdc-dialog__surface {
      padding: var(--space-xl);
      width: 800px;
    }
  }
}

.nv-mat-dialog-wide-tall {
  mat-dialog-container {
    .mdc-dialog__surface {
      padding: var(--space-xl);
      width: 800px;
      height: 600px;
    }
  }
}

.nv-mat-dialog-extra-wide {
  mat-dialog-container {
    .mdc-dialog__surface {
      padding: var(--space-xxl);
      width: 1000px;
    }
  }
}

.nv-mat-dialog-wide-no-padding {
  mat-dialog-container {
    .mdc-dialog__surface {
      padding: 0;
      width: 800px;
      max-height: 90vh;
      min-width: 700px;
      min-height: 500px;
    }
  }
}

// define styles for dialogs that are configured with this panelClass. See modals.service for examples of methods that
// open an instance of the modal service using a configuration that contains `nv-mat-dialog-common-markup` - JYR
.nv-mat-dialog-common-markup {
  mat-dialog-container {
    .mdc-dialog__surface {
      padding: var(--space-xl);
      overflow: hidden;
      width: 500px;
      overflow-y: auto;
      max-height: 95vh;
    }
  }

  mat-dialog-content {
    width: 100%;
    overflow: visible;
  }

  mat-form-field {
    @extend %nv-mat-form-field;
    .mat-form-field-infix {
      // display: inline-block;
      // background-color: var(--color-blue-03);
      // border: var(--space-xxxs) solid var(--color-blue-10);
      // border-radius: var(--space-xs);
      // padding: var(--space-s);
      border: 0;
      padding: 0;
    }

    .mat-mdc-input-element {
      @extend %nv-textbox-search;
      // color: var(--color-blue);
      // font-size: var(--font-s);
      // margin: var(--space-xs) 0;
      // padding: 0;
      // position: relative;
      width: 100%;
      box-sizing: border-box;
      height: var(--space-xxl);
      // display: inline-block;
    }

    .mat-form-field-ripple {
      background-color: transparent;
    }
  }

  mat-dialog-actions {
    // margin-bottom: 0;
    margin-top: var(--space-m);
    padding-top: 0;
    padding-bottom: 0;
  }
}

.nv-dialog {
  background: white;
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: var(--space-m);
  grid-template-areas: 'title' 'message' 'controls';
  grid-template-rows: minmax(var(--space-xxl), auto) 1fr auto;
  border-radius: var(--space-xxs);
  box-sizing: border-box;
  overflow: auto;
  color: var(--color-blue);

  .dialog-head {
    grid-area: title;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: var(--space-m);
    > div.icon,
    > h2 {
      align-self: center;
    }
  }

  .dialog-message {
    grid-area: message;
    display: grid;
    grid-auto-flow: row;
    align-content: center;
    grid-row-gap: var(--space-m);
  }

  .dialog-controls {
    display: grid;
    grid-area: controls;
    grid-auto-flow: column;
    align-self: flex-end;
    text-align: right;
    justify-content: flex-end;
    grid-column-gap: var(--space-s);
  }

  .mat-mdc-form-field {
    width: 436px;

    .mat-form-field-label {
      position: relative;
      margin-left: 43px;
      color: var(--color-blue-50);
    }

    &.mat-focused .mat-form-field-label {
      color: transparent;
    }
  }

  .mdc-line-ripple {
    height: 0;
    display: none;
  }

  .mat-mdc-text-field-wrapper {
    padding-bottom: 0;
  }

  .mat-mdc-input-element {
    @extend %nv-textbox-search;
    width: 86%;
  }
}

// custom styling for nv-design components rendering in modals
// TODO: see if this should be moved once the design library is back in this repo (JE)

.cdk-overlay-container {
  .nv-multi-picker-menu {
    max-height: calc(50vh - 48px);
    width: 410px;
    max-width: 410px;

    .mat-mdc-menu-content {
      width: 390px;
    }

    .searchbox {
      position: absolute;
      z-index: 10;
      top: 0;
      padding-top: 8px;
      padding-bottom: 8px;
      background-color: white;
      width: 390px;

      nv-icon {
        top: 8px;
      }
    }

    .option-pills {
      margin-top: 42px;
      width: 390px;
    }
  }
}

.icon-wrapper {
  nv-icon {
    opacity: 0.5;
    bottom: -4px;
    &:hover {
      cursor: pointer;
    }
  }
}
