// NOTE!!: resetting the original mat-menu's unpleasant padding - jchu
// applicable to the whole app
.cdk-overlay-pane {
  min-width: 150px; // default min-width of cdk-overlay prior to angular material v15
  &:not(.nv-multi-picker-panel) {
    transform: translateY(0px) !important; // prevents weird overlap (AThom)
  }

  .mat-mdc-menu-panel.mdc-menu-surface {
    min-height: unset; // reset angular material 8's default min height
    max-width: unset;
    .mat-mdc-menu-content {
      padding: 0;
    }
    .mat-mdc-menu-ripple.mat-ripple .mat-ripple-element {
      background-color: var(--color-blue) !important;
      opacity: 0.05;
    }
  }
  // reset undesired border radius and padding of mat-menu when invoked from mat-form-field
  .mat-mdc-select-panel {
    &.mdc-menu-surface {
      &.mat-primary {
        &.mdc-menu-surface--open {
          padding: 0;
          border-radius: 5px;
        }
      }
    }
  }
}
