// dimension variables can be used anywhere (margin, padding, border size, shadows, etc
// so dont use any prefix that constrains where these might be used
:root {
  --space-xxxs: 1px;
  --space-xxs: 2px;
  --space-xs: 4px;
  --space-s: 8px;
  --space-m: 16px;
  --space-l: 24px;
  --space-xl: 32px;
  --space-xxl: 40px;
  --space-xxxl: 48px;
  --space-4xl: 64px;
  --space-5xl: 96px;
  --space-6xl: 192px;

  // usage: box-shadows...
  --elevation-1: 0px 1px 3px rgba(0, 0, 0, 0.25);
  --elevation-2: 0px 2px 5px rgba(0, 0, 0, 0.25);
  --elevation-3: 0px 4px 10px rgba(0, 0, 0, 0.25);
  --elevation-4: 0px 11px 15px -7px rgba(0, 0, 0, 0.25), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --elevation-nav-option: 0px 3px 10px rgba(0, 0, 0, 0.3); // legacy
  --elevation-sticky-header: 0 4px 2px -2px rgba(0, 0, 0, 0.25);
  --elevation-history-log-side-nav: inset 4px 0px 4px -4px rgba(0, 0, 0, 0.3); // Shadow only on the left side

  --scroll-bar: 12px;

  // Element spacing
  // This should move to the app
  --sidebar-header-height: 144px;
  --sidebar-min-width: 208px;
  --content-min-width: 864px;
  --right-nav-header: var(--sidebar-header-height);

  --z-idx-neg: -1;
  --z-idx-1: 1;
  --z-idx-standard: 10;
  --z-idx-header: 50;
  --z-idx-elevated: 60;
}
