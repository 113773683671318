/*
  component: mat-checkbox

  `<mat-checkbox disableRipple class="nv-mat-checkbox"></mat-checkbox>`
*/
/**
 *
 * currently only been used in - jchu:
 * flag-log-action-modal.component.html
 * school-tools-sync.component.html
 * cluster-user-modal-shell.component.html
 * cluster-user-portfolio.component.html
 *
 */
.nv-mat-checkbox {
  display: flex;
  margin: 0px;

  /**
    * Most of the styles here reset checkbox with class nv-mat-checkbox to what it looked prior to the angular material v15 upgrade
    *
    * Styles applied with the !important CSS rule are needed because of how high the specifity angular material CSS styles are applied with
  */
  .mdc-form-field {
    height: 16px;
    margin-left: 1px;
    .mdc-checkbox {
      cursor: default;
      margin: 0 -12px;
      padding-top: 9px;
      .mdc-checkbox__background,
      .mat-mdc-checkbox-touch-target {
        border-color: var(--color-blue) !important;
        // reset to default width and height prior to angular material v15
        width: 16px;
        height: 16px;
        .mdc-checkbox__mixedmark {
					border-radius: 16px; // default border-radio of mixedmarl prior to angular material v15
          width: 8px;
				}
      }
      //default position of checkbox prior to angular material v15
      .mdc-checkbox__native-control {
        top: 11px;
        left: 11px;
        width: 16px;
        height: 16px;
        &:not(:disabled) {
          cursor: pointer;
          z-index: 1;
        }
      }
    }

    label {
      padding-left: 6px;
      z-index: 1;
      color: var(--color-blue);
      font-size: 16px;
    }
  }

  // disables ripple in all checkbox states; checked, unchecked, indetermine, disabled
  .mdc-checkbox__ripple {
		background: unset !important;
	}
  &.mdc-checkbox--disabled {
    pointer-events: unset;
    .mdc-form-field {
      .mdc-checkbox {
        &:hover {
          cursor: not-allowed;
        }
        .mdc-checkbox__background {
          background-color: var(--color-blue-30) !important;
          border-color: var(--color-blue-30) !important;
        }
        // A lot of specificity needed when checkbox is disabled
        .mdc-checkbox__native-control[disabled] {
          &:not(:checked) {
            &:not(:indeterminate) {
              &:not([data-indeterminate=true])~.mdc-checkbox__background {
                background-color: unset !important;
                border-color: var(--color-blue-30) !important;
              }
            }
          }
        }
      }
      label {
        color: var(--color-blue-30);
      }
    }
  }
}

/*
 * used with .nv-mat-checkbox to override default square checkbox style to a circle (AB)
 * Currently being used in:
 * fixed-table.component.html
*/
.nv-circle-mat-checkbox {
  // base, unchecked layer of checkbox
  .mdc-form-field {
    .mdc-checkbox {
      .mdc-checkbox__background,
      .mat-mdc-checkbox-touch-target {
        border-color: var(--color-blue-20) !important;
        border-radius: 70%;
        border-width: thin;
      }
    }
  }

 // when checkbox enabled and checked
  &.mat-checkbox-checked .mat-checkbox-background {
    background-color: var(--color-blue-05);
  }

  &.mat-mdc-checkbox-checked {
    .mdc-form-field {
      .mdc-checkbox {
        .mdc-checkbox__background,
        .mat-mdc-checkbox-touch-target {
          background-color: var(--color-blue-05) !important;
          border-color: var(--color-blue) !important;
          scale: 1.1;
          svg {
            filter: brightness(0) saturate(100%) invert(23%) sepia(88%) saturate(2097%) hue-rotate(206deg) brightness(92%) contrast(96%);
            scale: 0.75;
          }
        }
      }
    }
  }

  &.mat-mdc-checkbox-checked {
    &.mdc-checkbox--disabled {
      .mdc-form-field {
        .mdc-checkbox {
          .mdc-checkbox__background,
          .mat-mdc-checkbox-touch-target {
            background-color: var(--color-blue-30) !important;
            border-color: var(--color-blue) !important;
          }
        }
      }
    }
  }
}

/**
 *
 * currently been used in main page and student profile page - jchu:
 * list-display-milestone.component.html
 * list-display-career-path.component.html
 * list-display-college-path.component.html
 * list-display-student.component.html
 *
 */
.nv-checkbox-wrapper {
  @extend %nv-checkbox;
}

// This will move to a separate component eventually

%nv-checkbox {
  padding: 0;
  justify-content: center;
  margin-left: var(--space-xs);

  .checkbox {
    height: var(--space-l);
    width: var(--space-l);
    display: inline-block;
    position: relative;
    border-radius: var(--space-xxs);
    background-image: url(/assets/v3/img/checkbox-off.png);
    background-position: center;
    background-size: contain;
    vertical-align: middle;

    &.checkbox-selected {
      background-image: url(/assets/v3/img/checkbox-on.png);
    }
  }
}
